import { Input } from '@/hooks/useInputs'
import locale from '@locales/en.yml'
import moment from 'moment-timezone'

const csrfToken: string = (() => {
    return document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content')
})()

const t = (key: string, def = undefined) => {
    const path = key.split('.')
    let i18n = locale['en']

    path.forEach((p) => {
        if (i18n[p]) {
            i18n = i18n[p]
        }
    })

    if (typeof i18n == 'object' && def) {
        return def
    }

    return i18n
}

const findKey = (obj: Record<string, unknown>, value: any) => {
    return Object.keys(obj).find((key) => obj[key] === value)
}

const formatNumber = (number: number) => (number < 10 ? `0${number}` : number)

const dt = (datetime: string | number) => {
    let parsed = moment(datetime)

    if (typeof datetime == 'number') {
        parsed = moment.unix(datetime)
    }

    return parsed.tz('Africa/Algiers')
}

const extractInputName = (name: string) => {
    const [extractedName] = name
        .match(/(\[[a-z_]+\])$/)
        .map((m) => m.replace(/\[|\]/g, ''))
    return extractedName
}

export { csrfToken, findKey, t, formatNumber, dt, extractInputName }
