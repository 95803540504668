import { NotificationTypes } from '@/components/Notification'
import { IconError, IconInfo, IconSuccess, IconWarning } from './icons'

const Icon = ({ type }: { type: NotificationTypes }) => {
    switch (type) {
        case 'success':
            return <IconSuccess />
        case 'error':
            return <IconError />
        case 'warning':
            return <IconWarning />
        default:
            return <IconInfo />
    }
}

export { Icon }
