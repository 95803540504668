import { extractInputName } from '@/helpers'
import { useInputs, type Input } from '@/hooks/useInputs'
import { addValue, getValue } from '@/stores/values'
import { useStore } from '@nanostores/react'
import { PropsWithChildren, useEffect, useState } from 'react'

type IProps = {
    filterField?: string
    filterPath?: string
    dependsOnField?: string
    dependsOnPath?: string
}

type ServerOption = [string, string | number]

type Option = {
    props: {
        defaultValue: string | number
        text: string
    }
}

const parseOptions = (options: ServerOption[]): Option[] => {
    const opts = options.map((op) => {
        const [text, defaultValue] = op

        return {
            props: {
                text,
                defaultValue
            }
        }
    })

    opts.unshift({
        props: {
            text: null,
            defaultValue: null
        }
    })

    return opts
}

const RelationSelect = ({
    children,
    filterField,
    filterPath,
    dependsOnField,
    dependsOnPath
}: PropsWithChildren<IProps>) => {
    const [fields] = useInputs(children)[0]
    const filterValue = useStore(getValue(filterField))
    const dependencyValue = useStore(getValue(dependsOnField))
    const [options, setOptions] = useState<Option[]>([])
    const [filteredOptions, setFilteredOptions] = useState<Option[]>([])

    const handleChange = (
        e: React.ChangeEvent<HTMLSelectElement>,
        input: Input
    ) => {
        const { value } = e.target
        const name = extractInputName(input.name)

        addValue(name, value)
    }

    const useOptions = (input: Input) => {
        const name = extractInputName(input.name)
        if (name == filterField) {
            if (dependencyValue) return filteredOptions
            return input.children || filteredOptions
        }

        return filterValue ? options : input.children || []
    }

    useEffect(() => {
        if (!filterValue || !filterPath) return

        fetch(`${filterPath}?${filterField}=${filterValue}`, {})
            .then((response) => response.json())
            .then((data: ServerOption[]) => {
                setOptions(() => parseOptions(data))
            })
    }, [filterValue])

    useEffect(() => {
        if (!dependsOnField) return

        fetch(`${dependsOnPath}?${dependsOnField}=${dependencyValue}`)
            .then((r) => r.json())
            .then((data: ServerOption[]) => {
                setFilteredOptions(() => parseOptions(data))
            })
    }, [dependencyValue])

    return (
        fields && (
            <div className={`grid grid-cols-${fields.length} gap-3`}>
                {fields.map(({ input, label }) => {
                    const selectOptions = useOptions(input)
                    return (
                        <div key={input.id}>
                            {input.type != 'hidden' && (
                                <label htmlFor={input.id}>{label.text}</label>
                            )}
                            <select
                                {...input}
                                onChange={(e) => handleChange(e, input)}
                            >
                                {selectOptions.map(({ props }) => {
                                    /* eslint react/prop-types: 0 */
                                    const { defaultValue, text } = props
                                    return (
                                        <option
                                            key={defaultValue}
                                            value={defaultValue}
                                        >
                                            {text}
                                        </option>
                                    )
                                })}
                            </select>
                        </div>
                    )
                })}
            </div>
        )
    )
}

export { RelationSelect }
