import { Close } from '@/components/Close'
import { Icon } from '@/components/Icon'
import './styles.scss'

type NotificationTypes =
    | 'success'
    | 'error'
    | 'warning'
    | 'info'
    | 'default'
    | 'danger'

type Props = {
    title?: string
    message: string
    type?: NotificationTypes
    node: any
    dismissible?: boolean
    unmount?: () => void
}

const Title = ({
    title,
    type,
    dismissible,
    close
}: {
    title: string
    type: NotificationTypes
    dismissible: boolean
    close: () => void
}) => {
    return (
        <>
            <p>
                <Icon type={type} />
                {title}
            </p>

            {dismissible && <Close onClose={close} />}
        </>
    )
}

const Notification = ({
    title = null,
    message,
    type = 'default',
    dismissible = false,
    node
}: Props) => {
    const close = () => node.unmount()
    return (
        <div
            className={`notification ${type}`}
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            data-mdb-autohide="false"
        >
            {title && (
                <div className="notification__title">
                    <Title
                        type={type}
                        title={title}
                        dismissible={dismissible}
                        close={close}
                    />
                </div>
            )}
            <div className="notification__content">
                {message}
                {dismissible && !title && <Close onClose={close} />}
            </div>
        </div>
    )
}
export { Notification, type NotificationTypes }
