// import { subscribe } from '@/channels/system'

import {
    DeleteButton,
    Map,
    Notification,
    MembersTable,
    RelationSelect
} from '@/components'
import { Components, registerCustomElements } from 'react-custom-mount'

const components: Components = {
    'delete-button': DeleteButton,
    'x-notification': Notification,
    'interactive-map': Map,
    'members-table': MembersTable,
    'relation-select': RelationSelect
}

const DOMReady = function (callback: () => void) {
    document.readyState === 'interactive' || document.readyState === 'complete'
        ? callback()
        : document.addEventListener('DOMContentLoaded', callback)
}
DOMReady(function () {
    registerCustomElements(components)
})

// subscribe((type) => {
//     if (type === 'reload') {
//         window.location.reload()
//     }
// })
