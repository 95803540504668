import { atom, computed, ReadableAtom } from 'nanostores'

type Values = {
    [key: string]: string
}

export const $values = atom<Values>({})

export const addValue = (key: string, value: string) => {
    $values.set({
        ...$values.get(),
        [key]: value
    })
}

export const getValue = (key: string): ReadableAtom<string> => {
    return computed($values, (values) => values[key])
}
