import { csrfToken } from '@/helpers'

type Props = {
    path: string
    confirm: string
    content: string
    className?: string
}
const DeleteButton = ({ path, confirm, content, className }: Props) => {
    const submit = (e) => {
        e.preventDefault()
        if (confirm) {
            if (window.confirm(confirm)) {
                e.target.submit()
                return true
            }
        }
    }
    return (
        <form action={path} method="POST" onSubmit={submit}>
            <input type="hidden" name="_method" value="DELETE" />
            <input type="hidden" name="authenticity_token" value={csrfToken} />
            <button
                className={className}
                dangerouslySetInnerHTML={{ __html: content }}
            ></button>
        </form>
    )
}

export { DeleteButton }
