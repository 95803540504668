import { WMSTileLayer, WMSTileLayerProps } from 'react-leaflet'

const url = '/api/tiles'

const Layer = ({
    params,
    ...props
}: WMSTileLayerProps & {
    layers?: string[]
    layer?: string
    url: string
}) => {
    return (
        <WMSTileLayer
            params={{
                format: 'image/png',
                version: '1.3.0',
                epsg: '4326',
                bgcolor: '0x98CEF2',
                tiled: true,
                ...params
            }}
            {...props}
        />
    )
}

const APILayer = ({
    layer,
    layers,
    ...props
}: {
    layer?: string
    layers?: string[]
}) => {
    return (
        <Layer
            url={url}
            layers={
                (layers && layers.map((layer) => `aina:${layer}`)) || [
                    `aina:${layer}`
                ]
            }
            {...props}
        />
    )
}

export { APILayer }
