import aina from '@assets/images/aina.png'

const logos = {
    aina
}

const Watermark = ({
    type,
    className
}: {
    type: 'aina' | 'oran2022'
    className?: string
}) => {
    return <img src={logos[type]} className={`watermark ${className}`} />
}

export { Watermark as default }
