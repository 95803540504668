// import { Tariq } from '@/components/Tariq'
import { CRS } from 'leaflet'
import { MapContainer } from 'react-leaflet'
import { APILayer } from './api_layer'

import Watermark from '@/components/Watermark'
import 'leaflet/dist/leaflet.css'
import '@changey/react-leaflet-markercluster/dist/styles.min.css'
import './styles.scss'

const initialCenter = [35.6485, -0.5998]
const initialZoom = 12

const Map = () => {
    return (
        <MapContainer
            center={initialCenter}
            zoom={initialZoom}
            crs={CRS.EPSG4326}
            attributionControl={false}
            zoomControl={false}
        >
            <APILayer layer="base_ar" />
            {/* <Tariq /> */}
            <Watermark type="aina" />
        </MapContainer>
    )
}

export { Map, initialCenter, initialZoom }
