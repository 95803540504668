import {
    Dispatch,
    OptionHTMLAttributes,
    PropsWithChildren,
    ReactElement,
    SetStateAction,
    useEffect,
    useState
} from 'react'

type Input = PropsWithChildren<{
    id: string
    name: string
    type?: string
    defaultValue?: string
    dir?: 'ltr' | 'rtl'
}>

type Label = {
    text: string
}

type IRow = {
    label: Label
    input: Input
}

const parseChildren = (children: ReactElement[]) => {
    const parsedRows = []

    children.map(({ props }) => {
        const { children: containers } = props
        const row = []

        containers.map(({ props: containerProps }) => {
            const { children: grandChildren } = containerProps
            const labelElement = grandChildren.find(
                (c: ReactElement) => c.type == 'label'
            )
            const text =
                labelElement != undefined ? labelElement.props.children : ''
            const { props: inputProps } = grandChildren.find(
                (c: ReactElement) =>
                    ['input', 'select'].includes(c.type as string)
            )
            const { id, name, type, children: inputChildren, dir } = inputProps
            let { defaultValue } = inputProps

            const selectedOption =
                inputChildren &&
                inputChildren.find(
                    ({
                        props
                    }: {
                        props: OptionHTMLAttributes<HTMLOptionElement>
                    }) => props.selected
                )

            defaultValue =
                defaultValue ??
                (selectedOption && selectedOption.props.defaultValue)

            const label = { text }
            const input = {
                id,
                name,
                type,
                defaultValue,
                children: inputChildren,
                dir
            }

            row.push({ label, input })
        })
        parsedRows.push(row)
    })

    return parsedRows
}

const useInputs = (
    children: ReactElement[]
): [IRow[][], Dispatch<SetStateAction<IRow[][]>>] => {
    const [rows, setRows] = useState<IRow[][]>([])

    useEffect(() => {
        setRows(parseChildren(children))
    }, [children])

    return [rows, setRows]
}

const useInputTemplates = (
    rows: IRow[][]
): [IRow[], Dispatch<SetStateAction<IRow[]>>] => {
    const [templates, setTemplates] = useState<IRow[]>([])

    useEffect(() => {
        setTemplates((t) => {
            if (rows.length == 0) return t

            const row = rows[0]

            const template = row.map(({ input, label }) => {
                return {
                    label,
                    input: {
                        ...input,
                        id: input.id.replace(/([0-9]+)/, '{}'),
                        name: input.name.replace(/([0-9]+)/, '{}'),
                        defaultValue: ''
                    }
                }
            })

            return template
        })
    }, [rows])

    return [templates, setTemplates]
}

export { useInputs, useInputTemplates, Label, Input, IRow }
