import { useInputs, useInputTemplates } from '@/hooks/useInputs'
import type { ReactElement } from 'react'

const AddIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
            />
        </svg>
    )
}

const RemoveIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M22 10.5h-6m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
            />
        </svg>
    )
}

const MembersTable = ({ children }: { children?: ReactElement[] }) => {
    const [rows, setRows] = useInputs(children)
    const [templates] = useInputTemplates(rows)
    const addRow = () => {
        setRows((r) => {
            const newIndex = r.length

            const newRow = templates.map(({ label, input }) => {
                return {
                    label,
                    input: {
                        ...input,
                        id: input.id.replace('{}', newIndex.toString()),
                        name: input.name.replace('{}', newIndex.toString())
                    }
                }
            })

            return [...r, newRow]
        })
    }

    const removeRow = (index: number) => {
        if (confirm('Are you sure?')) {
            setRows((rows) => {
                const row = rows[index]
                const { input: hidden } = row.find(
                    (r) => r.input.type == 'hidden'
                )
                const destroy_input = {
                    ...hidden,
                    name: hidden.name.replace(/\[([a-z]+\]$)/, '[_destroy]'),
                    id: hidden.id.replace(/(_[a-z]+$)/, '_destroy'),
                    defaultValue: '1'
                }

                return rows.map((row, i) => {
                    if (index != i) return row

                    row.unshift({
                        label: { text: '' },
                        input: destroy_input
                    })
                    return row
                })
            })
        }
    }

    return (
        <>
            <div className="flex justify-end mb-4">
                <button type="button" onClick={addRow}>
                    <AddIcon />
                </button>
            </div>
            <div className="flex flex-col gap-3">
                {rows.map((fields, index) => {
                    const marked_to_be_destroyed =
                        fields.filter((f) => f.input.id.endsWith('_destroy'))
                            .length > 0

                    return (
                        <div
                            className={
                                marked_to_be_destroyed
                                    ? 'hidden'
                                    : 'flex gap-3 items-center'
                            }
                            key={index}
                        >
                            {fields.map(({ label, input }) => {
                                return (
                                    <div
                                        className={
                                            input.type == 'hidden'
                                                ? 'hidden'
                                                : 'flex-grow'
                                        }
                                        key={input.id}
                                    >
                                        {input.type != 'hidden' && (
                                            <label htmlFor={input.id}>
                                                {label.text}
                                            </label>
                                        )}
                                        <input
                                            {...input}
                                            placeholder={label.text}
                                        />
                                    </div>
                                )
                            })}
                            <button
                                className="pt-4 flex-0"
                                type="button"
                                onClick={() => removeRow(index)}
                            >
                                <RemoveIcon />
                            </button>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export { MembersTable }
